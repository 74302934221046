<template>
<div class="app-fee-accounting-page">
  <div class="page__wrapper">
    <div class="page__header-actions">
      <a-button type="text" @click="toIndexPage" :style="{ padding: '0' }">
        <template #icon>
          <ArrowLeftOutlined />
        </template>
        返回首頁
      </a-button>
    </div>

    <div class="page__body">
      <a-typography-title :level="1" :style="{ marginBottom: '30px' }">手續費收支紀錄</a-typography-title>

      <!-- 總收取手續費用 -->
      <div class="body-item">
        <!-- <a-typography-title :level="3">總收取手續費用</a-typography-title> -->

        <div align="center">
          <a-statistic
            class="fee-statistic"
            title="總收取手續費用 (TCN)"
            :precision="2"
            :value="totalFeeAmount"
          />
        </div>
      </div>
      <!-- 總收取手續費用 -->

      <a-divider :style="{ margin: '40px 0' }" />

      <!-- 交易紀錄 -->
      <div class="body-item">
        <a-typography-title :level="3" :style="{ marginBottom: '16px' }">交易紀錄</a-typography-title>

        <!-- <a-radio-group v-model:value="recordCurrencyType" :style="{ marginBottom: '20px' }">
          <a-radio-button value="t-Coin">t-Coin</a-radio-button>
          <a-radio-button value="NTD">NTD</a-radio-button>
        </a-radio-group> -->

        <a-table
          :dataSource="recordsData"
          :columns="columns"
          :loading="loading"
          :style="{ marginBottom: '8px' }"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'type'">
              <span :style="{ color: record.type=='received' ? '#689F38' : 'red' }">{{ record.type }}</span>
            </template>

            <template v-if="column.key === 'service'">
              <span v-if='record.service === "bookcrossing"'>漂書</span>
            </template>

            <template v-if="column.key === 'demand_id'">
              {{ record.demand_user.id }}
            </template>

            <template v-if="column.key === 'supply_id'">
              {{ record.supply_user.id }}
            </template>

            <template v-if="column.key === 'amount'">
              <span :style="{ fontWeight: 'bold' }">{{ record.amount }}</span>
            </template>

            <template v-if="column.key === 'note'">
              {{ record.note }}
            </template>

            <template v-if="column.key === 'created_at'">
              {{ DateUtil.formatDatetime(record.created_at) }}
            </template>
          </template>
        </a-table>
      </div>
      <!-- 交易紀錄 -->
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import { defineComponent } from 'vue'
import { ArrowLeftOutlined } from '@ant-design/icons-vue'
import DateUtil from '@/utils/DateUtil'
import { message } from 'ant-design-vue'

export default defineComponent({
  components: {
    ArrowLeftOutlined
  },
  setup() {
    return {
      DateUtil
    }
  },
  async mounted() {
    await this.init();
  },
  data() {
    return {
      loading: false,

      // exchangesData: [],

      /* 交易紀錄 */
      // recordCurrencyType: 't-Coin',
      recordsData: [],
      columns: [{
        title: '交易 Id',
        dataIndex: 'tx_id',
        key: 'tx_id'
      }, {
        title: '服務',
        dataIndex: 'service',
        key: 'service'
      }, {
        title: '買家 Id',
        dataIndex: 'demand_id',
        key: 'demand_id'
      }, {
        title: '賣家 Id',
        dataIndex: 'supply_id',
        key: 'supply_id'
      }, {
        title: '費用 (TCN)',
        dataIndex: 'amount',
        key: 'amount'
      }, {
        title: '備註',
        dataIndex: 'note',
        key: 'note'
      }, {
        title: '交易時間',
        dataIndex: 'created_at',
        key: 'created_at'
      }]
    }
  },
  computed: {
    /* 總收取手續費用 */
    totalFeeAmount() {
      if (!this.loading && this.recordsData.length) {
        let totalAmount = 0;

        this.recordsData.forEach(record => {
          totalAmount += record.amount
        });

        return totalAmount
      }
      return null
    },
    // filteredRecordsData() {
    //   let data = [];

    //   this.recordsData.forEach(item => {
    //     if (item.currency == this.recordCurrencyType) {
    //       data.push(item);
    //     }
    //   });

    //   data = data.sort(function (a, b) {
    //     return a.created_at > b.created_at ? 1 : -1;
    //   });

    //   return data
    // }
  },
  methods: {
    /* router */
    toIndexPage() {
      this.$router.push({ name: 'AppIndex' });
    },

    /* init functions */
    async init() {
      this.loading = true;

      // await this.getExchangeAccounts();
      await this.getTransferRecords();
      // this.calBalanceOverview();

      this.loading = false;
    },

    /* functions */

    /* api */
    // async getExchangeAccounts() {
    //   const url = process.env.VUE_APP_API + '/users?role=3';
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${this.$store.state.auth.token}`
    //     }
    //   }
    //   const response = await axios.get(url, config);
    //   console.log(response.data);
    //   this.exchangesData = response.data;
    // },
    async getTransferRecords() {
      // init
      this.recordsData = [];
      this.totalTransferOutAmount = 0;

      // find transactions
      const url = process.env.VUE_APP_API + `/fee-transactions?coop_info=${this.$store.state.auth.info.coop_info.id}&to_user=${this.$store.state.auth.info.id}`;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.token}`
        }
      }
      const response = await axios.get(url, config);
      let resData = response.data;

      // sort by created_at
      resData = resData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

      this.recordsData = resData;
      console.log(resData);

      // combine exchage_info
      // for (let i=0; i<resData.length; i++) {
      //   const record = resData[i];

      //   let targetKey = ''; // to_user|from_user
      //   if (record.from_user.id == this.$store.state.auth.info.id) {
      //     record.type = 'expenditure';
      //     targetKey = 'to_user';
      //   } else { // Exchange -> Coop
      //     record.type = 'received';
      //     targetKey = 'from_user';
      //   }

      //   for (let j=0; j<this.exchangesData.length; j++) {
      //     const exchange = this.exchangesData[j];

      //     if (record[targetKey].id == exchange.id) {
      //       record.target_name = exchange.ex_user_info.exchange_name;
      //       break
      //     }
      //   }

      //   this.recordsData.push(record);
      //   this.totalTransferOutAmount += record.amount;
      // }
    }
  }
})
</script>

<style lang="scss">
.app-fee-accounting-page {
  .page__wrapper {
    width: 950px;
    margin: 0 auto;
  }

  .page__header-actions {
    margin-bottom: 12px;
  }

  .fee-statistic {
    .ant-statistic-title {
      font-size: 1.2rem;
    }

    .ant-statistic-content {
      font-size: 2.5rem;
    }
  }
}
</style>